<template>
  <div class="TopTable_wrap">
    <el-table
      :data="tableData"
      :header-cell-class-name="getHeaderCellClassName"
      :header-cell-style="getHeaderCellStyle"
      :border="true"
      :stripe="true"
    >
      <el-table-column
        prop="type"
        label="平台服务"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="content"
        label="服务内容"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="standard"
        label="收费标准"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="vip"
        label="VIP权益 1999/年"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="svip"
        label="SVIP权益 2999/年"
        align="center"
      ></el-table-column>
    </el-table>

    <div class="TopTable_btn_wrap">
      <div class="TopTable_btn_left" style="cursor:pointer">立即开通</div>
      <div class="TopTable_btn_right" style="cursor:pointer">立即开通</div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
export default {
  components: {},
  setup() {
    const tableData = [
      {
        type: "电子招投标管理系统",
        content: "工程建设招标管理",
        standard: "——",
        vip: "✅/❎",
        svip: "免费/折扣",
      },
      {
        type: "电子招投标管理系统",
        content: "政府采购招标管理",
        standard: "——",
        vip: "✅/❎",
        svip: "免费/折扣",
      },
      {
        type: "电子招投标管理系统",
        content: "企业招采管理系统",
        standard: "——",
        vip: "✅/❎",
        svip: "免费/折扣",
      },
      {
        type: "电子招投标管理系统",
        content: "供应商管理系统",
        standard: "——",
        vip: "✅/❎",
        svip: "免费/折扣",
      },
      {
        type: "电子招投标管理系统",
        content: "物料管理系统",
        standard: "——",
        vip: "✅/❎",
        svip: "价格/月、季、年免费",
      },
      {
        type: "电子招投标管理系统",
        content: "专家管理系统",
        standard: "——",
        vip: "✅/❎",
        svip: "价格/月、季、年免费",
      },
    ];
    const data = reactive({
      tableData,
    });

    const getHeaderCellClassName = (e) => {
      const { row, columnIndex, rowIndex } = e;
      const className =
        // eslint-disable-next-line no-constant-condition
        columnIndex == 0
          ? "BaseColumnBg"
          : columnIndex == 3
          ? "VipColumnBg"
          : columnIndex == 4
          ? "SvipColumBg"
          : "BaseColumnBg";
      return className;
    };

    const getHeaderCellStyle = (e) => {
      const { row, column, rowIndex, columnIndex } = e;
      const BgColor =
        // eslint-disable-next-line no-constant-condition
        columnIndex == 0
          ? {
              "background-color": "#E2EFFD",
              "text-align": "center",
              "font-size": "18px",
              padding: "10px",
            }
          : columnIndex == 3
          ? {
              "background-color": "#FFF8E3",
              "text-align": "center",
              "font-size": "18px",
              padding: "10px",
            }
          : columnIndex == 4
          ? {
              "background-color": "#DEFFFF",
              "text-align": "center",
              "font-size": "18px",
              padding: "10px",
            }
          : {
              "background-color": "#E2EFFD",
              "text-align": "center",
              "font-size": "18px",
              padding: "10px",
            };
      return BgColor;
    };

    return {
      ...data,
      getHeaderCellClassName,
      getHeaderCellStyle,
    };
  },
};
</script>

<style scoped>
@import url("./CenterTable.css");
</style>

